import styled from "styled-components";
import WHLabel from "../../components/WHLabel/WHLabel";

export const StyledNetDescription = styled.div`
  margin-bottom: 20px;
`;

export const StyledDescription = styled.div`
  padding: 0 110px;

  @media (max-width: 992px) {
    padding: 0 35px;
  }
`;

export const StyledLabel = styled(WHLabel)`
  font-size: 24px !important;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 992px) {
    font-size: 18px !important;
  }
`;
