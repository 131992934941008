import React from "react";
import PropTypes from "prop-types";
import {
  StyledContainer,
  StyledCaption,
  StyledCompactContainer,
  StyledCompactCaption,
  StyledRecipesHeroContainer,
} from "./WHHero.styled";
import hero from "../../assets/hero/hero.jpg";
import heroCompact from "../../assets/hero/hero-compact.jpg";
import heroRecipes from "../../assets/recipes.jpg";
import WHButton from "../WHButton/WHButton";
import { Col, Row } from "react-bootstrap";
import WHLabel from "../WHLabel/WHLabel";
import { Link } from "react-router-dom";

const WHHero = ({
  normal,
  compact,
  recipes,
  title,
  label,
  btnLabel,
  labelSecond,
  className,
}) => {
  return (
    <>
      {normal && (
        <StyledContainer
          style={{ backgroundImage: `url(${hero})` }}
          className={className}
        >
          <StyledCaption>
            <WHLabel className="px-3 mb-4" tag="h1" text={title} />
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <WHLabel className="px-3 mb-4" tag="p" text={label} />
              </Col>
            </Row>
            <Link className="link-label" to="/dried-porcini">
              <WHButton className="mt-3" label={btnLabel} btnStyle="primary" />
            </Link>
          </StyledCaption>
        </StyledContainer>
      )}

      {compact && (
        <StyledCompactContainer
          className={className}
          style={{ backgroundImage: `url(${heroCompact})` }}
        >
          <StyledCompactCaption>
            <WHLabel className="px-3 mb-4" tag="h1" text={title} />
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <WHLabel className="px-3 mb-3" tag="p" text={label} />
                <WHLabel className="px-3 mb-4" tag="p" text={labelSecond} />
              </Col>
            </Row>
          </StyledCompactCaption>
        </StyledCompactContainer>
      )}
      {recipes && (
        <StyledRecipesHeroContainer
          className={className}
          style={{ backgroundImage: `url(${heroRecipes})` }}
        />
      )}
    </>
  );
};

WHHero.propTypes = {
  label: PropTypes.string,
};

export default WHHero;
