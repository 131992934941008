import React from "react";
import { Col, Row } from "react-bootstrap";
import WHLabel from "../WHLabel/WHLabel";
import { StyledInfoContainer, StyledDescription } from "./WHInfo.styled";
import infoBG from "../../assets/info/infoBG.jpg";
import WHLinkLabel from "../WHLinkLabel/WHLinkLabel";

const WHInfo = ({
  titleOne,
  labelOne,
  labelOneSecond,
  titleTwo,
  labelTwo,
  labelTwoSecond,
  imageOne,
  imageTwo,
  labelRecipe,
  className,
}) => (
  <StyledInfoContainer
    style={{ backgroundImage: `url(${infoBG})` }}
    titleOne={titleOne}
    titleTwo={titleTwo}
    labelOne={labelOne}
    labelOneSecond={labelOneSecond}
    labelTwo={labelTwo}
    labelTwoSecond={labelTwoSecond}
    imageOne={imageOne}
    imageTwo={imageTwo}
    className={className}
  >
    <Row className="pb-4">
      <Col lg={6} className="order-2 order-lg-1 mb-4 d-flex">
        <StyledDescription firstText={true}>
          <WHLabel tag="h2" text={titleOne} />
          <WHLabel tag="p" text={labelOne} />
          <WHLabel tag="p" text={labelOneSecond} />
        </StyledDescription>
      </Col>
      <Col lg={6} className="order-1 order-lg-2 mb-5">
        <img className="img-fluid w-100" src={imageOne} alt={titleOne} />
      </Col>
    </Row>
    <Row className="pt-4">
      <Col lg={6} className="mb-5">
        <img className="img-fluid w-100" src={imageTwo} alt={titleTwo} />
      </Col>
      <Col lg={6} className="d-flex">
        <StyledDescription secondText={true}>
          <WHLabel tag="h2" text={titleTwo} />
          <WHLabel tag="p" text={labelTwo} />
          <WHLabel tag="p" text={labelTwoSecond} />
          <WHLinkLabel label={labelRecipe} link="/recipes" />
        </StyledDescription>
      </Col>
    </Row>
  </StyledInfoContainer>
);

export default WHInfo;
