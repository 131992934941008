import { Container, Row } from "react-bootstrap";
import WHContactForm from "../../components/WHContactForm/WHContactForm";
import WHHealthBenefit from "../../components/WHHealthBenefit/WHHealthBenefit";
import WHHero from "../../components/WHHero/WHHero";
import WHNav from "../../components/WHNav/WHNav";
import { StyledCol } from "./HealthBenefit.styled";
import { benefits } from "./model/benefits";

const HealthBenefit = () => {
  return (
    <>
      <WHNav />
      <Container>
        <WHHero
          compact={true}
          title="The New Superfood Porcini Mushrooms"
          label="As time progresses there are studies that will show Wilde Hills Porcini Mushrooms should be a part of health conscious peoples diets and We think it is the new superfood."
          labelSecond="To learn more about emerging just discovered health benefits of this Wilde treasure look at the individual studies we have found that say good things."
        />

        <Row className="my-5">
          <StyledCol md={6} firstcol="true">
            {benefits.length &&
              benefits
                .slice(0, 4)
                .map(({ title, text, link }, index) => (
                  <WHHealthBenefit
                    linkLabel
                    key={`${index} - ${title}`}
                    className="mb-5"
                    title={title}
                    text={text}
                    link={link}
                  />
                ))}
          </StyledCol>
          <StyledCol md={6} secondcol="true">
            {benefits.length &&
              benefits
                .slice(4)
                .map(({ title, text, link }, index) => (
                  <WHHealthBenefit
                    linkLabel
                    key={`${index} - ${title}`}
                    className="mb-5"
                    title={title}
                    text={text}
                    link={link}
                  />
                ))}
          </StyledCol>
        </Row>
      </Container>
      <WHContactForm />
    </>
  );
};

export default HealthBenefit;
