import styled from "styled-components";

export const StyledIngidients = styled.div`
  padding: 50px 20px 20px;
  background: center center no-repeat;
  background-size: cover;
  min-height: 530px;
`;

export const StyledInstructions = styled.div`
  padding: 50px 20px 20px;
`;

export const StyledUList = styled.ul`
  list-style-type: none;

  li {
    position: relative;
    margin-bottom: 10px;

    &:before {
      position: absolute;
      content: "+";
      font-size: 1.3rem;
      font-weight: bolder;
      background-color: transparent;
      padding-left: 12px;
      padding-right: 0px;
      color: var(--blue);
      left: -34px;
      top: -5px;
    }

    &::marker {
      content: "" !important;
      background-color: transparent;
    }
  }
`;

export const StyledUListInstructions = styled.ul`
  counter-reset: id;
  list-style-type: none;

  li {
    counter-increment: id;
    margin-bottom: 20px;
    position: relative;

    &:before {
      content: counter(id, decimal-leading-zero) " ";
      font-size: 1.3rem;
      font-weight: bolder;
      padding-left: 12px;
      padding-right: 0px;
      color: var(--blue);
      position: absolute;
      top: -5px;
      left: -42px;
      border: 0;
    }

    &::marker {
      content: "" !important;
      background-color: transparent;
    }
  }
`;
