import Router from "./Router";
import WHFooter from "./components/WHFooter/WHFooter";
import { StyledContainer } from "./App.styled";

const App = () => {
  return (
    <StyledContainer>
      <Router />
      <WHFooter />
    </StyledContainer>
  );
};

export default App;
