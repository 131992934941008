import styled from "styled-components";
import WHLabel from "../WHLabel/WHLabel";

export const StyledSuperFoodContainer = styled.div`
  width: 80%;
  margin: 0 auto 75px;
`;

export const StyledLine = styled.div`
  margin: 0 auto 75px;
`;

export const StyledLabel = styled(WHLabel)`
  color: var(--blue);
`;
