import styled from "styled-components";
import WHLabel from "../WHLabel/WHLabel";

export const StyledProductContainer = styled.div`
  padding: 0 100px 65px;

  @media (max-width: 576px) {
    padding: 0 60px 30px;
  }
`;

export const StyledLabel = styled(WHLabel)`
  font-size: 24px !important;

  @media (max-width: 992px) {
    font-size: 18px !important;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  a {
    display: flex;
    /* width: fit-content; */
  }
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;
