import WHLabel from "../WHLabel/WHLabel";
import WHLinkLabel from "../WHLinkLabel/WHLinkLabel";
import { StyledContainer } from "./WHHealthBenefit.styled";

const WHHealthBenefit = ({ title, text, link, className, linkLabel }) => {
  return (
    <StyledContainer className={className}>
      <WHLabel className="mb-3" tag="h4" text={title} />
      <WHLabel tag="p" text={text} />
      {linkLabel && (
        <WHLinkLabel
          label="Read more"
          link={link}
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
    </StyledContainer>
  );
};

export default WHHealthBenefit;
