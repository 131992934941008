import styled from "styled-components";

export const StyledContainer = styled.div`
  background: center center no-repeat;
  width: 100%;
  height: 754px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

export const StyledCaption = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 90px;
  position: relative;
  z-index: 1;
`;

export const StyledCompactContainer = styled(StyledContainer)`
  height: 550px;
  justify-content: center;

  @media (min-width: 992px) {
    height: 378px;
  }
`;

export const StyledRecipesHeroContainer = styled(StyledContainer)`
  height: 265px;

  @media (min-width: 992px) {
    height: 378px;
  }
`;

export const StyledCompactCaption = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
`;

export const StyledCarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 754px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
`;

export const StyledImgWrapper = styled.div`
  position: absolute;
  bottom: 0;

  img {
    @media (max-width: 768px) {
      height: 550px;
    }
  }
`;

export const StyledBtnContainer = styled.div`
  padding: 0 16px;
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 5px;
  }
`;
