import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo/logo.svg";
import { Container } from "react-bootstrap";
import { StyledButton, StyledSpan, StyledContainer } from "./WHNav.styled";

const WHNav = () => {
  const [state, setState] = useState("hide");
  const [collapsed, setcollapsed] = useState("");

  const onClick = () => {
    state === "hide" ? setState("show") : setState("hide");
  };

  const onContactClick = () => {
    setcollapsed("collapsed");
    onClick();
  };

  const productClass =
    location.pathname.match(/^\/retail-products\/dried-porcini/) ||
    location.pathname.match(/^\/retail-products\/moms-jam/)
      ? "nav-link dropdown-toggle active"
      : "nav-link dropdown-toggle";

  const productClassRestaurant =
    location.pathname.match(
      /^\/restaurant-products\/restaurant-dried-porcini/
    ) ||
    location.pathname.match(
      /^\/restaurant-products\/restaurant-frozen-porcini/
    ) ||
    location.pathname.match(
      /^\/restaurant-products\/restaurant-frozen-chanterelle/
    )
      ? "nav-link dropdown-toggle active"
      : "nav-link dropdown-toggle";

  const healthClass =
    location.pathname.match(/^\/mushrooms/) ||
    location.pathname.match(/^\/fruits/)
      ? "nav-link dropdown-toggle active"
      : "nav-link dropdown-toggle";

  return (
    <StyledContainer>
      <nav className="navbar navbar-expand-lg bg-white">
        <Container fluid className="px-0">
          <Link
            className="navbar-brand"
            to="/"
            onClick={() => setState("hide")}
          >
            <img src={logo} alt="logo" />
          </Link>
          <StyledButton
            className={`navbar-toggler ${collapsed} collapsed custom-toggler shadow-none d-flex d-lg-none flex-column justify-content-around`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={onClick}
          >
            <StyledSpan className="top-bar"></StyledSpan>
            <StyledSpan className="middle-bar"></StyledSpan>
            <StyledSpan className="bottom-bar"></StyledSpan>
          </StyledButton>
          <div
            className={`${state} collapse navbar-collapse justify-content-end`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 text-uppercase border--top">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/"
                  activeclassname="active"
                  onClick={onClick}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={productClass}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Retail Products
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="/retail-products/dried-porcini"
                    >
                      Dried porcini
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/retail-products/moms-jam"
                    >
                      Jams
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={productClassRestaurant}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Restaurant Products
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="/restaurant-products/restaurant-dried-porcini"
                    >
                      Dried porcini
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/restaurant-products/restaurant-frozen-porcini"
                    >
                      Frozen Porcini
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/restaurant-products/restaurant-frozen-chanterelle"
                    >
                      Frozen Chanterelle
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/restaurant-products/restaurant-frozen-raspberries"
                    >
                      Frozen Raspberries
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={healthClass}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Health benefit
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li>
                    <a className="dropdown-item" href="/mushrooms">
                      Mushrooms
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/fruits">
                      Fruits
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/recipes"
                  activeclassname="active"
                  onClick={onClick}
                >
                  Recipes
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#contact"
                  onClick={onContactClick}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </nav>
    </StyledContainer>
  );
};

export default WHNav;
