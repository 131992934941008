import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import WHButton from "../WHButton/WHButton";
import {
  StyledProductContainer,
  StyledLabel,
  StyledContainer,
  StyledWrapper,
  StyledButtonWrapper,
} from "./WHProductHome.styled";

const WHPRoductHome = ({
  title,
  labelHome,
  image,
  btnLabel,
  btnLabelSecondary,
  className,
  secondButton,
  url,
  urlSecondary,
}) => {
  return (
    <StyledProductContainer
      labelHome={labelHome}
      image={image}
      className={className}
      btnLabel={btnLabel}
      btnLabelSecondary={btnLabelSecondary}
    >
      <Row>
        <Col lg={6}>
          <img className="img-fluid w-100" src={image} alt={title} />
        </Col>
        <Col lg={6} className="d-flex pe-0 pe-lg-5">
          <StyledContainer>
            <StyledWrapper>
              <StyledLabel tag="p" text={labelHome} className="mb-5" />
              <StyledButtonWrapper>
                <Link className="link-label text-center" to={url}>
                  <WHButton btnStyle="primary" label={btnLabel} />
                </Link>
                {secondButton && (
                  <Link className="link-label text-center" to={urlSecondary}>
                    <WHButton btnStyle="primary" label={btnLabelSecondary} />
                  </Link>
                )}
              </StyledButtonWrapper>
            </StyledWrapper>
          </StyledContainer>
        </Col>
      </Row>
    </StyledProductContainer>
  );
};

export default WHPRoductHome;
