import React from "react";
import { StyledLinkLabel } from "./WHLinkLabel.styled";
import arrow from "../../assets/arrow.svg";

const WHLinkLabel = ({ label, className, link, ...otherProps }) => {
  return (
    <StyledLinkLabel
      label={label}
      className="link-label"
      href={link}
      {...otherProps}
    >
      {label} <img src={arrow} alt="arrow" />
    </StyledLinkLabel>
  );
};

export default WHLinkLabel;
