import styled from "styled-components";
import { Container } from "react-bootstrap";

export const StyledNotFoundContainer = styled.div`
  display: flex;
  padding: 200px 100px 100px;
  justify-content: center;

  @media (max-width: 576px) {
    padding: 60px;
  }
`;

export const StyledContainer = styled(Container)``;
