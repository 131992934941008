import styled, { css } from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  height: 60px;
  width: 100%;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 8px 25px;
  border-radius: 15px;
  border-style: solid;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out,
    border-style 0.5s ease-in-out;

  ${(props) =>
    props.btnStyle === "primary" &&
    css`
      background-color: var(--blue);
      color: var(--white);
      border-color: transparent;

      &:hover {
        background-color: var(--darkBlue);
      }
    `}

  ${(props) =>
    props.btnStyle === "secondary" &&
    css`
      background-color: var(--white);
      color: var(--blue);
      border-color: transparent;
      border-width: 2px;

      &:hover {
        background-color: transparent;
        border-color: var(--white);
        border-width: 2px;
        color: var(--white);
        box-shadow: none;
      }
    `}
`;
