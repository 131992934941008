import React from "react";
import PropTypes from "prop-types";
import { StyledLabel } from "./WHLabel.styled";

const WHLabel = ({ tag, text, className, style }) => {
  return (
    <StyledLabel as={tag} text={text} className={className} style={style}>
      {text}
    </StyledLabel>
  );
};

WHLabel.propTypes = {
  text: PropTypes.string,
  tag: PropTypes.node.isRequired,
};

export default WHLabel;
