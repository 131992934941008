import React from "react";
import PropTypes from "prop-types";
import WHLabel from "../WHLabel/WHLabel";
import {
  StyledWrapper,
  StyledBenefitsContainer,
} from "./WHBenefitsGroup.styled";
import { Col, Row } from "react-bootstrap";
import WHBenefits from "../WHBenefits/WHBenefits";
import wilde from "../../assets/icons/wild.svg";
import nutritious from "../../assets/icons/Nutritious.svg";
import free from "../../assets/icons/free.svg";
import flavour from "../../assets/icons/flavour.svg";
import planetSafe from "../../assets/icons/planet-safe.svg";

const WHBenefitsGroup = () => (
  <StyledBenefitsContainer>
    <Row className="mb-5">
      <Col>
        <WHLabel
          tag="h2"
          text="Why choose Wilde Hills?"
          className="text-center"
        />
      </Col>
    </Row>
    <Row>
      <Col md={4} className="px-4 d-flex justify-content-center mb-5">
        <WHBenefits
          icon={wilde}
          title="Wilde"
          labelBold="Wilde food means food that is not farmed or planted. "
          labelNormal="Wilde food grows all around us. It grows, ripens, and is harvested in its natural environment. It synchronizes us with the seasons and connects us to the untamed parts of our nature."
        />
      </Col>
      <Col md={4} className="px-4 d-flex justify-content-center mb-5">
        <WHBenefits
          icon={nutritious}
          title="Nutritious"
          labelBold="Wilde food is the pinnacle of healthy eating. "
          labelNormal="It is the most nutrient dense and the least processed food that you can put into your body. Did you know that wilde mushrooms are full of protein and boost your immune system? You can learn more about that here."
        />
      </Col>
      <Col md={4} className="px-4 d-flex justify-content-center mb-5">
        <WHBenefits
          icon={free}
          title="Gluten & dairy-free"
          labelBold="Our products suit a large range of diets. "
          labelNormal="Though traditionally paired with an array of cream, cheese, nuts, pasta, and meats they do not contain any of these. All products are vegan-friendly."
        />
      </Col>
    </Row>
    <Row>
      <Col md={6} className="px-5 mb-5">
        <StyledWrapper justifyEnd={true}>
          <WHBenefits
            icon={flavour}
            title="Delicious"
            labelBold="Wilde food brings so much flavor per bite it can not be compared to any mass produced thing. "
            labelNormal=" It tastes better because as nature intended it comes from rich un-depleted habitats…. Dried wilde food is even more concentrated in flavor. Simple preparations bring about some serious gastronomic pleasure."
          />
        </StyledWrapper>
      </Col>
      <Col md={6} className="px-5 mb-5">
        <StyledWrapper>
          <WHBenefits
            icon={planetSafe}
            title="Planet safe"
            labelBold="We use low impact harvesting methods. "
            labelNormal="We do things the old fashion way… by hand. We use natural drying techniques, and but a fraction of the abundance of these edible resources is collected. Our harvests are always free of herbicides, pesticides, and fertilizers originating from untamed and remote areas."
          />
        </StyledWrapper>
      </Col>
    </Row>
  </StyledBenefitsContainer>
);

export default WHBenefitsGroup;
