import { Col, Container, Image, Row } from "react-bootstrap";
import WHHero from "../../components/WHHero/WHHero";
import WHLabel from "../../components/WHLabel/WHLabel";
import Jar1 from "../../assets/restaurant-products/Chanterelle/chanterelle.jpg";
import { StyledLabel } from "./Products.styled";
import WHContactForm from "../../components/WHContactForm/WHContactForm";
import WHNav from "../../components/WHNav/WHNav";

const RestaurantFrozenChanterelle = () => {
  return (
    <>
      <WHNav />
      <Container>
        <WHHero
          className="mb-5"
          compact={true}
          title="Frozen Chanterelle Mushrooms"
          label="Wilde Hills Frozen Chanterelle mushrooms are only available at our fine restaurant partners in the following packaging:"
          labelSecond="Frozen Chanterelle 2.2 Lbs (1000 g)"
        />

        <Row className="mb-5">
          <Col sm={12} md={6} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="Frozen Chanterelle"
              style={{ color: "var(--blue)" }}
            />
            <StyledLabel
              className="d-inline fw-normal"
              tag="p"
              text="Net weight "
            />
            <StyledLabel
              className="d-inline fw-bold"
              tag="p"
              text="2.2 Lb (1000 g)"
            />
            <Image className="m-auto w-50 d-block" src={Jar1} />
          </Col>
        </Row>
      </Container>
      <WHContactForm />
    </>
  );
};

export default RestaurantFrozenChanterelle;
