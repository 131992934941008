import React from "react";
import WHBenefitsGroup from "../../components/WHBenefitsGroup/WHBenefitsGroup";
import WHSuperFood from "../../components/WHSuperFood/WHSuperFood";
import WHContactForm from "../../components/WHContactForm/WHContactForm";
import { Container } from "react-bootstrap";
import WHNav from "../../components/WHNav/WHNav";
import WHHeroCarousel from "../../components/WHHero/WHHeroCarousel";
import WHCarouselHomeProduct from "../../components/WHCarousel/WHCarouselHomeProduct";
import WHLabel from "../../components/WHLabel/WHLabel";

const Home = () => {
  return (
    <>
      <WHNav />
      <Container>
        <WHHeroCarousel
          title="Bring nature to your kitchen"
          label="Premium, wild, hand picked, hand selected and hand packaged product delivered with love and care to you."
          btnLabel="Discover Retail Products"
          btnLabel2="Discover Restaurant Products"
        />

        <WHBenefitsGroup />

        <WHLabel
          tag="h2"
          text="Our products"
          className="text-center mt-5 mb-4"
        />

        <WHCarouselHomeProduct />
        <WHSuperFood
          className="text-center"
          title="The new superfood Porcini Mushrooms"
          label="As time progresses there are studies that will show Wilde Hills Porcini Mushrooms should be a part of health conscious peoples diets and We think it is the new superfood."
          linkLabel="Read more"
          linkURL="/mushrooms"
        />
      </Container>
      <WHContactForm />
    </>
  );
};

export default Home;
