import { Col, Container, Image, Row } from "react-bootstrap";
import WHHero from "../../components/WHHero/WHHero";
import WHInfo from "../../components/WHInfo/WHInfo";
import imageOne from "../../assets/info/image-one.jpg";
import imageTwo from "../../assets/info/image-two.jpg";
import WHLabel from "../../components/WHLabel/WHLabel";
import frozenSliced from "../../assets/restaurant-products/dried-porcini/frozen_sliced.jpg";
import frozenWhole from "../../assets/restaurant-products/dried-porcini/frozen_whole.jpg";
import frozenCubed from "../../assets/restaurant-products/dried-porcini/frozen_cubed.jpg";
import { StyledNetDescription, StyledLabel } from "./Products.styled";
import WHContactForm from "../../components/WHContactForm/WHContactForm";
import WHNav from "../../components/WHNav/WHNav";

const RestaurantFrozenPorcini = () => {
  return (
    <>
      <WHNav />
      <Container>
        <WHHero
          className="mb-5"
          compact={true}
          title="Frozen Porcini Mushrooms"
          label="Wilde Hills Frozen Porcini mushrooms are only available at our fine restaurant partners in the following packaging:"
          labelSecond="Frozen Sliced Porcini 2.2 Lbs (1000 g), Frozen Whole Porcini 2.2 Lbs (1000 g), Frozen Cubed Porcini 2.2 Lbs (1000 g)"
        />

        <Row className="mb-5">
          <Col sm={12} md={6} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="Frozen Sliced Porcini"
              style={{ color: "var(--blue)" }}
            />
            <StyledLabel
              className="d-inline fw-normal"
              tag="p"
              text="Net weight "
            />
            <StyledLabel
              className="d-inline fw-bold"
              tag="p"
              text="2.2 Lb (1000 g)"
            />
            <Image className="m-auto w-50 d-block" src={frozenSliced} />
          </Col>
          <Col sm={12} md={6} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="Frozen Whole Porcini"
              style={{ color: "var(--blue)" }}
            />
            <StyledNetDescription>
              <StyledLabel className="d-inline" tag="p" text="Net weight " />
              <StyledLabel
                className="d-inline fw-bold"
                tag="p"
                text="2.2 Lb (1000 g)"
              />
            </StyledNetDescription>

            <Image className="m-auto w-50 d-block" src={frozenWhole} />
          </Col>
          <Col sm={12} md={6} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="Frozen Cubed Porcini"
              style={{ color: "var(--blue)" }}
            />
            <StyledNetDescription>
              <StyledLabel className="d-inline" tag="p" text="Net weight " />
              <StyledLabel
                className="d-inline fw-bold"
                tag="p"
                text="2.2 Lb (1000 g)"
              />
            </StyledNetDescription>

            <Image className="m-auto w-50 d-block" src={frozenCubed} />
          </Col>
        </Row>
        <Row>
          <Col>
            <WHInfo
              titleOne="What are Porcini Mushrooms?"
              labelOne="Porcini mushrooms are brown-capped mushrooms with thick, white stalks. The caps can range in size from an inch to nearly a foot, but most collected specimens are no more than a few inches. The caps have a convex shape when young, giving them the ideal appearance for mushrooms, and require no prep other than a quick clean."
              labelOneSecond="Because of their status in fine cuisine, their short season, and how difficult they are to cultivate, porcini mushrooms can be pricey."
              imageOne={imageOne}
              titleTwo="What Do Porcini Mushrooms taste like?"
              labelTwo="Porcini mushrooms are often described as nutty and earthy with a meatiness in flavor and texture. They have a similar taste to other, more common mushrooms, but with a deeper and nuttier flavor. Fresh mushrooms have a tender, meaty texture when cooked."
              labelTwoSecond="Dried porcini add a deep, mushroom flavor to broths or sauces and, once rehydrated, have a slightly chewy texture."
              imageTwo={imageTwo}
              labelRecipe="Find our recipe suggestions"
            />
          </Col>
        </Row>
      </Container>
      <WHContactForm />
    </>
  );
};

export default RestaurantFrozenPorcini;
