import styled from "styled-components";

export const StyledBenefitsContainer = styled.div`
  padding: 30px 0 0;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: ${(props) => (props.justifyEnd ? "end" : "start")};
  }
`;
