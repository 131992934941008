import { Col, Container, Image, Row } from "react-bootstrap";
import WHHero from "../../components/WHHero/WHHero";
import WHInfo from "../../components/WHInfo/WHInfo";
import imageOne from "../../assets/info/image-one.jpg";
import imageTwo from "../../assets/info/image-two.jpg";
import WHLabel from "../../components/WHLabel/WHLabel";
import Jar1 from "../../assets/wh-jar-1.jpg";
import Jar2 from "../../assets/wh-jar-2.jpg";
import celophaneDriedPorcini from "../../assets/restaurant-products/dried-porcini/cellophane_dried_porcini_1oz_beg.jpg";
import powderDriedPorcini from "../../assets/restaurant-products/dried-porcini/powder-2oz.jpg";
import {
  StyledNetDescription,
  StyledDescription,
  StyledLabel,
} from "./Products.styled";
import WHContactForm from "../../components/WHContactForm/WHContactForm";
import WHNav from "../../components/WHNav/WHNav";

const Products = () => {
  return (
    <>
      <WHNav />
      <Container>
        <WHHero
          className="mb-5"
          compact={true}
          title="Dried Porcini Mushrooms"
          label="Wilde Hills Dried Porcini Mushrooms are a premium, wild, hand picked, hand selected and hand packaged
        product delivered with love and care to you. We provide this product as nature intended: pesticide free,
        herbicide free and contaminant free."
          labelSecond="Wilde Hills dried porcini mushrooms are only available at our fine retail partners in either 1 oz (28 g) or 4.23 oz (120 g) packaging."
        />

        <Row className="mb-5">
          <Col sm={12} md={6} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="Glass Jar"
              style={{ color: "var(--blue)" }}
            />
            <StyledLabel
              className="d-inline fw-normal"
              tag="p"
              text="Net weight "
            />
            <StyledLabel
              className="d-inline fw-bold"
              tag="p"
              text="1 oz (28 g)"
            />
            <Image className="m-auto w-50 d-block" src={Jar1} />
            <StyledDescription>
              <WHLabel
                className="m-auto"
                tag="p"
                text="Perfect for making cream of mushrooms soup, risotto, or a beautiful porcini mushroom pasta sauce"
              />
            </StyledDescription>
          </Col>
          <Col sm={12} md={6} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="PET Jar"
              style={{ color: "var(--blue)" }}
            />
            <StyledNetDescription>
              <StyledLabel className="d-inline" tag="p" text="Net weight " />
              <StyledLabel
                className="d-inline fw-bold"
                tag="p"
                text="4.23 oz (120 g)"
              />
            </StyledNetDescription>

            <Image className="m-auto w-50 d-block" src={Jar2} />
          </Col>
          <Col sm={12} md={6} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="Dried powder Porcini"
              style={{ color: "var(--blue)" }}
            />
            <StyledNetDescription>
              <StyledLabel className="d-inline" tag="p" text="Net weight " />
              <StyledLabel
                className="d-inline fw-bold"
                tag="p"
                text="2 oz (56.7 g)"
              />
            </StyledNetDescription>

            <Image className="m-auto w-50 d-block" src={powderDriedPorcini} />
          </Col>
          <Col sm={12} md={6} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="Cellophane pouch Porcini"
              style={{ color: "var(--blue)" }}
            />
            <StyledNetDescription>
              <StyledLabel className="d-inline" tag="p" text="Net weight " />
              <StyledLabel
                className="d-inline fw-bold"
                tag="p"
                text="1 oz (28 g)"
              />
            </StyledNetDescription>

            <Image
              className="m-auto w-50 d-block"
              src={celophaneDriedPorcini}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <WHInfo
              titleOne="What are Porcini Mushrooms?"
              labelOne="Porcini mushrooms are brown-capped mushrooms with thick, white stalks. The caps can range in size from an inch to nearly a foot, but most collected specimens are no more than a few inches. The caps have a convex shape when young, giving them the ideal appearance for mushrooms, and require no prep other than a quick clean."
              labelOneSecond="Because of their status in fine cuisine, their short season, and how difficult they are to cultivate, porcini mushrooms can be pricey."
              imageOne={imageOne}
              titleTwo="What Do Porcini Mushrooms taste like?"
              labelTwo="Porcini mushrooms are often described as nutty and earthy with a meatiness in flavor and texture. They have a similar taste to other, more common mushrooms, but with a deeper and nuttier flavor. Fresh mushrooms have a tender, meaty texture when cooked."
              labelTwoSecond="Dried porcini add a deep, mushroom flavor to broths or sauces and, once rehydrated, have a slightly chewy texture."
              imageTwo={imageTwo}
              labelRecipe="Find our recipe suggestions"
            />
          </Col>
        </Row>
      </Container>
      <WHContactForm />
    </>
  );
};

export default Products;
