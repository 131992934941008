import styled from "styled-components";

export const StyledInfoContainer = styled.div`
  padding: 115px 65px;
  background: center center no-repeat;
  background-size: cover;
  min-height: 1425px;

  @media (min-width: 1200px) and (max-width: 1400px) {
    min-height: 1325px;
  }

  @media (min-width: 1025px) {
    padding: 145px 100px;
  }

  @media (max-width: 576px) {
    padding: 100px 30px;
  }
`;

export const StyledDescription = styled.div`
  padding-right: unset;
  padding-left: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 992px) {
    padding-right: ${(props) => (props.firstText ? "60px" : "")};
    padding-left: ${(props) => (props.secondText ? "60px" : "")};
  }
`;
