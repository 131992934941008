export const benefits = [
  {
    title:
      "Purification and characterization of a novel protein with activity against non-small-cell lung cancer in vitro and in vivo from the edible mushroom Boletus edulis",
    text: "A new anti-tumor protein (designated as Boletus edulis or in short BEAP) was isolated from dried fruit bodies of the edible bolete mushroom Boletus edulis.These results prove that BEAP is a new multifunctional protein with anti-tumor and anti-metastasis capabilities.",
    link: "https://pubmed.ncbi.nlm.nih.gov/33508361/",
  },
  {
    title:
      "Boletus edulis biologically active biopolymers induce cell cycle arrest in human colon adenocarcinoma cells",
    text: "The use of biologically active compounds isolated from edible mushrooms against cancer raises global interest. Anticancer properties are mainly attributed to biopolymers including mainly polysaccharides, polysaccharopeptides, polysaccharide proteins, glycoproteins and proteins….Our results indicate that a biopolymer BE3 from Boletus edulis possesses anticancer potential and may provide a new therapeutic/preventive option in colon cancer chemoprevention.",
    link: "https://pubmed.ncbi.nlm.nih.gov/23370594/",
  },
  {
    title:
      "Boletus edulis ribonucleic acid - a potent apoptosis inducer in human colon adenocarcinoma cells",
    text: "Despite the large popularity of the Boletus edulis mushroom, little is known about its influence on human health and the possibilities of its therapeutic use. Nevertheless, several reports revealed the usefulness of biopolymers isolated from it in cancer treatment. The obtained results indicate the high therapeutic potential of the BE3 fraction against colon cancer, yet it is necessary to further confirm fraction efficacy and safety in animal and clinical studies.",
    link: "https://pubmed.ncbi.nlm.nih.gov/27302173/",
  },
  {
    title:
      "A King Bolete, Boletus edulis (Agaricomycetes), RNA Fraction Stimulates Proliferation and Cytotoxicity of Natural Killer Cells Against Myelogenous Leukemia Cells",
    text: "Numerous studies indicate the crucial role of natural killer (NK) cells in the prevention of tumor growth and inhibition of their metastasis, which suggests the possibility of their use in cancer treatment. We demonstrate here, to our knowledge for the first time, that B. edulis RNA enhances NK cell activity and possesses immunomodulatory potential.",
    link: "https://pubmed.ncbi.nlm.nih.gov/28605323/",
  },
  {
    title:
      "New insights into the molecular mechanism of Boletus edulis ribonucleic acid fraction (BE3) concerning antiproliferative activity on human colon cancer cells",
    text: "One of the relatively new and promising strategies of cancer treatment is chemoprevention, which involves the use of natural or synthetic compounds to block, inhibit or reverse carcinogenesis. A valuable and still untapped source of chemopreventive compounds seems to be edible mushrooms belonging to higher Basidiomycetes. Boletus edulis biopolymers extracted with hot water and purified by anion-exchange chromatography showed antiproliferative activity in colon cancer cells, but only fraction BE3, mostly composed of ribonucleic acids…. Thus, the previously and currently obtained results indicate that the BE3 fraction from Boletus edulis has great potential and needs to be further exploited through animal and clinical studies in order to develop a new efficient and safe therapeutic strategy for people who have been threatened by or suffered from colon cancer.",
    link: "https://pubmed.ncbi.nlm.nih.gov/28401225/",
  },
  {
    title:
      "Antioxidant and Antiproliferative Potential of Fruiting Bodies of the Wild-Growing King Bolete Mushroom, Boletus edulis (Agaricomycetes), from Western Serbia",
    text: "The aim of this work was to study the bioactivity of crude aqueous and ethanolic extracts of Boletus edulis prepared from caps and stipes of wild-growing basidiocarps collected from the Prijepolje region (western Serbia). This is, to our knowledge, the first report of the antiproliferative effects of crude aqueous and ethanolic extracts prepared from caps and stipes of wild-growing basidiocarps of B. edulis on the human breast MCF-7 cancer cell line.",
    link: "https://pubmed.ncbi.nlm.nih.gov/28322144/",
  },
  {
    title:
      "Dried Wild-Grown Mushrooms Can Be Considered a Source of Selected Minerals",
    text: "Dried mushrooms might be a source of mineral components, which are indispensable for human health. The aim of this study was to determine the contents of calcium (Ca), magnesium (Mg), iron (Fe), zinc (Zn), copper (Cu), manganese (Mn), and selenium (Se) in dried wild-grown mushrooms (Boletus edulis and Xerocomus badius).  We have shown that dried B. edulis can be considered a source of Se.",
    link: "https://pubmed.ncbi.nlm.nih.gov/35807930/",
  },
];

export const fruits = [
  {
    title: "Ellagic Acid",
    text: "Ellagic acid is a polyphenol and it is well known for its strong antioxidant action. This antioxidant action is linked to several health benefits that include, antimicrobial, anticancer, prebiotic and anti-inflammatory effects. ",
  },
  {
    title: "Flavonols",
    text: "Flavonols are associated with the health of the heart and possess anticancer, antiviral and stroke reducing effects. Kaempferol and quercetin and type of flavonols present in the plum.",
  },
  {
    title: "Anthocyanins",
    text: "Anthocyanins are the coloured pigments that are found in the fruits and vegetables and are associated with the urinary tract health. They also possess anti-ageing properties along with improving eye health and memory.",
  },
  {
    title: "Lutein",
    text: "Lutein is a carotenoid that is directly linked to the health of the eye and protects against heart diseases and macular degeneration.",
  },
];
