import { Container, Row } from "react-bootstrap";
import WHContactForm from "../../components/WHContactForm/WHContactForm";
import WHHealthBenefit from "../../components/WHHealthBenefit/WHHealthBenefit";
import WHHero from "../../components/WHHero/WHHero";
import WHNav from "../../components/WHNav/WHNav";
import { StyledCol } from "./HealthBenefit.styled";
import { fruits } from "./model/benefits";

const HealthBenefit = () => {
  return (
    <>
      <WHNav />
      <Container>
        <WHHero
          compact={true}
          title="Moms Plum Jam"
          label="Done the old fashion way… by hand. We use old country techniques, a fraction of these edible resources is collected. Has Ellagic Acid, Flavonols, Anthocyanins and Lutein."
          labelSecond="Taste. It tastes better because as nature intended it comes from rich un-depleted habitats... Simple preparations bring about some gastronomic pleasure."
        />

        <Row className="my-5">
          <StyledCol md={6} firstcol="true">
            {fruits.length &&
              fruits
                .slice(0, 2)
                .map(({ title, text }, index) => (
                  <WHHealthBenefit
                    key={`${index} - ${title}`}
                    className="mb-5"
                    title={title}
                    text={text}
                  />
                ))}
          </StyledCol>
          <StyledCol md={6} secondcol="true">
            {fruits.length &&
              fruits
                .slice(2)
                .map(({ title, text }, index) => (
                  <WHHealthBenefit
                    key={`${index} - ${title}`}
                    className="mb-5"
                    title={title}
                    text={text}
                  />
                ))}
          </StyledCol>
        </Row>
      </Container>
      <WHContactForm />
    </>
  );
};

export default HealthBenefit;
