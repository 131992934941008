import { Container } from "react-bootstrap";
import styled from "styled-components";

export const StyledButton = styled.button`
  width: 30px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;

  .top-bar {
    margin-top: 0;
    transform: rotate(135deg);
  }

  .middle-bar {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .bottom-bar {
    margin-top: 0;
    transform: rotate(-135deg);
  }

  &.collapsed .top-bar {
    margin-top: -14px;
    transform: rotate(0deg);
  }

  &.collapsed .middle-bar {
    opacity: 1;
    filter: alpha(opacity=100);
  }

  &.collapsed .bottom-bar {
    margin-top: 15px;
    transform: rotate(0deg);
  }
`;

export const StyledSpan = styled.span`
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--green);
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;

  .middle-bar {
    margin-top: 0;
  }
`;

export const StyledContainer = styled(Container)`
  .nav-item {
    margin-left: 10px;

    @media (max-width: 992px) {
      margin-left: 0;
    }
  }
  .dropdown-menu {
    border: 0;
    border-radius: 0;
    border-left: 3px solid var(--green);

    .dropdown-item {
      font-weight: 600;
      color: var(--bs-nav-link-color);
      &:focus,
      &:hover {
        color: var(--green);
        background-color: transparent;
      }
    }
  }

  .dropdown-toggle::after {
    content: "";
    display: inline-block;
    margin-left: 8px;
    vertical-align: 0.16em;
    width: 10px;
    height: 10px;
    border-top: 1.8px solid;
    border-right: 1.8px solid;
    border-left: 0;
    transform: rotate(135deg);
  }
`;
