import styled from "styled-components";

export const StyledWHFooterWrapper = styled.div`
  color: var(--white);
`;

export const StyledFooterContainer = styled.div`
  position: relative;
  background-color: var(--blue);
  margin-top: auto;

  &:before {
    content: "";
    width: calc(100% - 100px);
    height: 1px;
    background-color: var(--white);
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
