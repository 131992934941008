import styled, { css } from "styled-components";
import WHLabel from "../WHLabel/WHLabel";

export const StyledBenefits = styled.div`
  text-align: center;
  width: 360px;
`;

export const StyledLabel = styled(WHLabel)`
  color: var(--blue);
  margin: 12px 0;
`;
