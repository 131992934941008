import { Route, Routes } from "react-router-dom";
import HealthBenefit from "./pages/HealthBenefit/HealthBenefit.jsx";
import HealthBenefitFruits from "./pages/HealthBenefit/HealthBenefitFruits.jsx";
import Home from "./pages/Home/Home.jsx";
import Products from "./pages/Products/Products.jsx";
import NotFound from "./pages/NotFound/NotFound.jsx";
import Recipes from "./pages/RecipesPage/Recipes.jsx";
import ScrollToTop from "./ScrollToTop";
import ProductsJam from "./pages/Products/ProductsJam.jsx";
import RestaurantDriedPorcini from "./pages/Products/RestaurantDriedPorcini.jsx";
import RestaurantFrozenPorcini from "./pages/Products/RestaurantFrozenPorcini.jsx";
import RestaurantFrozenChanterelle from "./pages/Products/RestaurantFrozenChanterelle.jsx";
import RestaurantFrozenRaspberries from "./pages/Products/RestaurantFrozenRaspberries.jsx";

const Router = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/retail-products/dried-porcini" element={<Products />} />
        <Route path="/retail-products/moms-jam" element={<ProductsJam />} />
        <Route
          path="/restaurant-products/restaurant-dried-porcini"
          element={<RestaurantDriedPorcini />}
        />
        <Route
          path="/restaurant-products/restaurant-frozen-porcini"
          element={<RestaurantFrozenPorcini />}
        />
        <Route
          path="/restaurant-products/restaurant-frozen-chanterelle"
          element={<RestaurantFrozenChanterelle />}
        />
        <Route
          path="/restaurant-products/restaurant-frozen-raspberries"
          element={<RestaurantFrozenRaspberries />}
        />
        <Route path="mushrooms" element={<HealthBenefit />} />
        <Route path="fruits" element={<HealthBenefitFruits />} />
        <Route path="recipes" element={<Recipes />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  );
};

export default Router;
