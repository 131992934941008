import { Container } from "react-bootstrap";
import WHLabel from "../WHLabel/WHLabel";
import {
  StyledWHFooterWrapper,
  StyledFooterContainer,
} from "./WHFooter.styled";

const WHFooter = () => {
  const year = new Date().getFullYear();
  return (
    <StyledFooterContainer>
      <Container>
        <StyledWHFooterWrapper className="py-3">
          <WHLabel
            tag="p"
            text={`All rights reserved © ${year} Wilde Hills`}
            className="text-center mb-0"
          />
        </StyledWHFooterWrapper>
      </Container>
    </StyledFooterContainer>
  );
};

export default WHFooter;
