import React from "react";
import PropTypes from "prop-types";
import WHLabel from "../WHLabel/WHLabel";
import { StyledLabel, StyledBenefits } from "./WHBenefits.styled";

const WHBenefits = ({ title, icon, labelBold, labelNormal, className }) => (
  <StyledBenefits
    title={title}
    labelBold={labelBold}
    labelNormal={labelNormal}
    icon={icon}
    className={className}
  >
    <img src={icon} alt="benefits" />
    <StyledLabel tag="h3" text={title} />
    <WHLabel tag="span" text={labelBold} className="fw-bold" />
    <WHLabel tag="span" text={labelNormal} />
  </StyledBenefits>
);

WHBenefits.propTypes = {
  labelBold: PropTypes.string,
  labelNormal: PropTypes.string,
  title: PropTypes.string,
};

export default WHBenefits;
