import { Col, Container, Image, Row } from "react-bootstrap";
import WHHero from "../../components/WHHero/WHHero";
import WHLabel from "../../components/WHLabel/WHLabel";
import raspberries from "../../assets/raspberries/raspberries.jpg";
import { StyledLabel } from "./Products.styled";
import WHContactForm from "../../components/WHContactForm/WHContactForm";
import WHNav from "../../components/WHNav/WHNav";

const RestaurantFrozenRaspberries = () => {
  return (
    <>
      <WHNav />
      <Container>
        <WHHero
          className="mb-5"
          compact={true}
          title="Frozen Raspberries"
          label="Fragrant and full of flavour, Wilde Hills raspberries have unique aromas which are the result of the rich and fertile soil in which they are grown, as well as the fact that they are often cultivated on small family farms. Western Serbia is the worldwide capital for raspberries and boasts one of the most unspoiled and healthy environments in the whole country."
          labelSecond="Wilde Hills Premium Frozen Raspberries are carefully hand picked, hand selected and hand packaged product delivered with love and care to you."
        />

        <Row className="mb-5">
          <Col sm={12} md={6} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="Frozen Raspberries"
              style={{ color: "var(--blue)" }}
            />
            <StyledLabel
              className="d-inline fw-normal"
              tag="p"
              text="Net weight "
            />
            <StyledLabel
              className="d-inline fw-bold"
              tag="p"
              text="5 Lbs (2,268 g)"
            />
            <Image className="m-auto w-50 d-block" src={raspberries} />
          </Col>
        </Row>
      </Container>
      <WHContactForm />
    </>
  );
};

export default RestaurantFrozenRaspberries;
