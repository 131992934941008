import styled from "styled-components";

export const StyledFormWrapper = styled.div`
  background-color: var(--blue);
  width: 100%;
  color: var(--white);
  padding: 90px 20px;
`;

export const StyledInput = styled.input`
  border: 0px;
  border-bottom: 1px solid var(--white);
  border-color: var(--white);
  background-color: transparent;
  width: 100%;
  color: var(--white);
  padding: 20px 20px 10px 0;

  &::placeholder {
    color: var(--white);
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

export const StyledTextarea = styled.textarea`
  border: 0px;
  border-bottom: 1px solid var(--white);
  border-color: var(--white);
  background-color: transparent;
  width: 100%;
  color: var(--white);
  padding: 20px 20px 0 0;
  resize: none;

  &::placeholder {
    color: var(--white);
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
