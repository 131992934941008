import { Col, Container, Row } from "react-bootstrap";
import WHHero from "../../components/WHHero/WHHero";
import WHLabel from "../../components/WHLabel/WHLabel";
import {
  StyledIngidients,
  StyledInstructions,
  StyledUList,
  StyledUListInstructions,
} from "./Recipes.styled";
import infoBG from "../../assets/info/infoBG.jpg";
import WHContactForm from "../../components/WHContactForm/WHContactForm";
import WHNav from "../../components/WHNav/WHNav";

const Recipes = () => {
  return (
    <>
      <WHNav />
      <Container>
        <WHHero className="mb-5" recipes={true} />
        <Row>
          <Col>
            <WHLabel
              className="text-center"
              tag="h1"
              text="Dried porcini soup"
            />
          </Col>
        </Row>
        <Row className="mt-4 marginbottom-7">
          <Col md={4}>
            <StyledIngidients style={{ backgroundImage: `url(${infoBG})` }}>
              <WHLabel tag="h4" text="Ingridients" />
              <StyledUList>
                <li>2 oz of dried porcini mushrooms</li>
                <li>1 cup hot water </li>
                <li>4 cups vegetable stock</li>
                <li>2 cups heavy cream</li>
                <li>1/2 of cup dried white wine</li>
                <li>1 white onion</li>
                <li>2 garlic cloves </li>
                <li>2 cups peeled and diced golden potatoes </li>
                <li>1 bay leaf</li>
                <li>Salt and black pepper</li>
              </StyledUList>
            </StyledIngidients>
          </Col>
          <Col md={8}>
            <StyledInstructions>
              <WHLabel tag="h4" text="Instructions" />
              <StyledUListInstructions>
                <li>
                  Boil water in a pot, then pour over the mushrooms, allow to
                  rehydrate for 10 minutes and then agitate the mushrooms
                  vigorously in their liquid to remove any dirt. Remove the
                  mushrooms from their liquid, strain the liquid through a fine
                  strainer, and then add back to the mushrooms.
                </li>

                <li>
                  Saute the onion until soft and translucent in a soup pot, add
                  fine chopped garlic, season it lightly. Add the wine and cook
                  until most of it has evaporated, then add the potatoes,
                  vegetable stock, porcini and their liquid.
                </li>

                <li>
                  Cook the soup covered on 420 F°at a gentle simmer until the
                  potatoes are very tender, about 15 minutes.
                </li>

                <li>Add the cream, puree the mixture in a blender.</li>

                <li>
                  Season the soup with salt, then season with a little bit of
                  ground black pepper.
                </li>

                <li>
                  When the soup is finished, top with the croutons. 1 cup of
                  soup per person is plenty, it is very rich.
                </li>

                <li>Bon appetite.</li>
              </StyledUListInstructions>
            </StyledInstructions>
          </Col>
        </Row>
      </Container>
      <WHContactForm />
    </>
  );
};

export default Recipes;
