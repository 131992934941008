import React from "react";
import PropTypes from "prop-types";
import {
  StyledCaption,
  StyledCarouselContainer,
  StyledImgWrapper,
  StyledBtnContainer,
} from "./WHHero.styled";
import WHButton from "../WHButton/WHButton";
import { Col, Row } from "react-bootstrap";
import WHLabel from "../WHLabel/WHLabel";
import { Link } from "react-router-dom";
import WHCarousel from "../WHCarousel/WHCarousel";
import papirus from "../../assets/hero/hero-image-slider-bottom.png";

const WHHeroCarousel = ({ title, label, btnLabel, btnLabel2 }) => {
  return (
    <>
      <StyledCarouselContainer>
        <WHCarousel />
        <StyledCaption>
          <WHLabel className="px-3 mb-4" tag="h1" text={title} />
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <WHLabel className="px-3 mb-4" tag="p" text={label} />
            </Col>
          </Row>
          <StyledBtnContainer>
            <Link className="link-label" to="/retail-products/dried-porcini">
              <WHButton className="mt-3" label={btnLabel} btnStyle="primary" />
            </Link>

            <Link
              className="link-label"
              to="/restaurant-products/restaurant-dried-porcini"
            >
              <WHButton className="mt-3" label={btnLabel2} btnStyle="primary" />
            </Link>
          </StyledBtnContainer>
        </StyledCaption>
        <StyledImgWrapper>
          <img src={papirus} alt="" />
        </StyledImgWrapper>
      </StyledCarouselContainer>
    </>
  );
};

WHHeroCarousel.propTypes = {
  label: PropTypes.string,
};

export default WHHeroCarousel;
