import styled from "styled-components";

export const StyledCarousel = styled.div`
  position: relative;
  .carousel-item {
    width: 100%;

    p {
      color: var(--black);
    }
  }
`;

export const StyledCarouselItem = styled.div`
  background: center center no-repeat;
  height: 435px;
  background-size: cover;
  background-position: right;
  @media (max-width: 1200px) {
    background-position: center;
    height: 450px;
  }
`;
