import React from "react";
import PropTypes from "prop-types";
import { StyledButton } from "./WHButton.styled";

const WHButton = ({
  label,
  btnStyle,
  onClick,
  className,
  showLoader,
  ...otherProps
}) => {
  const content = showLoader ? (
    <>
      <span className="me-2">{label}</span>
      <div className="spinner-border text-primary" role="status"></div>
    </>
  ) : (
    label
  );

  return (
    <StyledButton
      label={label}
      btnStyle={btnStyle}
      onClick={onClick}
      className={className}
      {...otherProps}
    >
      {content}
    </StyledButton>
  );
};

WHButton.propTypes = {
  label: PropTypes.string,
};

export default WHButton;
