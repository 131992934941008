import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  padding-bottom: 25px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100px;
    height: 3px;
    bottom: 0;
    background-color: var(--blue);
  }
`;
