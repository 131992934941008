import { Col } from "react-bootstrap";
import styled from "styled-components";

export const StyledCol = styled(Col)`
  padding-right: ${(props) => (props.firstcol ? "12px" : "")};
  padding-left: ${(props) => (props.secondcol ? "12px" : "")};

  @media (min-width: 992px) {
    padding-right: ${(props) => (props.firstcol ? "50px" : "")};
    padding-left: ${(props) => (props.secondcol ? "50px" : "")};
  }
`;
