import { Col, Container, Image, Row } from "react-bootstrap";
import WHHero from "../../components/WHHero/WHHero";
import WHLabel from "../../components/WHLabel/WHLabel";
import Jar1 from "../../assets/plum-jam-5oz.jpg";
import { StyledLabel } from "./Products.styled";
import WHContactForm from "../../components/WHContactForm/WHContactForm";
import WHNav from "../../components/WHNav/WHNav";

const ProductsJam = () => {
  return (
    <>
      <WHNav />
      <Container>
        <WHHero
          className="mb-5"
          compact={true}
          title="Jams"
          label="Made of indigenous delicious plums from the Western Serbia mountains. Plums are full of phytochemicals and antioxidants. Known as the powerhouse of vitamin C, A, B1 and K and for the minerals calcium, zinc, potassium, phosphorus and magnesium."
          labelSecond="Consume Moms Plum Jam. Improve health conditions like osteoporosis, constipation to anti-ageing effects, plum benefits are great."
        />

        <Row className="mb-5">
          <Col sm={12} md={{ span: 6, offset: 3 }} className="text-center mb-5">
            <WHLabel
              tag="h2"
              className="mb-3"
              text="Glass Jar"
              style={{ color: "var(--blue)" }}
            />
            <StyledLabel
              className="d-inline fw-normal"
              tag="p"
              text="Net weight "
            />
            <StyledLabel
              className="d-inline fw-bold"
              tag="p"
              text="5 oz (141.7 g)"
            />
            <Image className="m-auto w-50 d-block" src={Jar1} />
          </Col>
        </Row>
      </Container>
      <WHContactForm />
    </>
  );
};

export default ProductsJam;
