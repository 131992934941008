import React from "react";
import { Col, Row } from "react-bootstrap";
import WHLabel from "../../components/WHLabel/WHLabel";
import { StyledNotFoundContainer, StyledContainer } from "./NotFound.styled";
import notFoundImg from "../../assets/not-found.svg";
import WHLinkLabel from "../../components/WHLinkLabel/WHLinkLabel";
import WHNav from "../../components/WHNav/WHNav";

const NotFound = ({}) => {
  return (
    <>
      <WHNav />
      <StyledContainer>
        <StyledNotFoundContainer>
          <Row>
            <Col
              md={6}
              className="d-flex flex-column justify-content-center mb-4"
            >
              <WHLabel tag="h1" text="WOOPS!!" />
              <WHLabel
                tag="p"
                text="We couldn't find the page you are looking for."
              />
              <WHLinkLabel label="Go back" link="/" />
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center">
              <img className="img-fluid w-100" src={notFoundImg} alt="woops" />
            </Col>
          </Row>
        </StyledNotFoundContainer>
      </StyledContainer>
    </>
  );
};

export default NotFound;
