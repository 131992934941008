import React from "react";
import WHLabel from "../WHLabel/WHLabel";
import WHLinkLabel from "../WHLinkLabel/WHLinkLabel";
import {
  StyledSuperFoodContainer,
  StyledLine,
  StyledLabel,
} from "./WHSuperFood.styled";

const WHSuperFood = ({ title, label, linkLabel, className, linkURL }) => {
  return (
    <StyledSuperFoodContainer
      title={title}
      label={label}
      className={className}
      linkLabel={linkLabel}
    >
      <StyledLine />
      <StyledLabel tag="h2" text={title} className="mb-4" />
      <WHLabel tag="h3" text={label} className="mb-4" />
      <WHLinkLabel label={linkLabel} link={linkURL} />
    </StyledSuperFoodContainer>
  );
};

export default WHSuperFood;
