import React from "react";
import homeProduct from "../../assets/home-product.jpg";
import homeProductJam from "../../assets/plum-jam-home.jpg";
import { StyledCarousel } from "./WHCarousel.styled";
import WHPRoductHome from "../WHProductsHome/WHProductHome";

const WHCarouselHomeProduct = ({}) => {
  return (
    <StyledCarousel
      id="carouselExampleIndicatorsHomeProduct"
      className="carousel slide w-100 carousel-dark"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicatorsHomeProduct"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicatorsHomeProduct"
          aria-current="true"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <WHPRoductHome
            labelHome="Wilde Hills Dried Porcini Mushrooms are a premium, wild, hand picked, hand selected and hand packaged product delivered with love and care to you. We provide this product as nature intended: pesticide free, herbicide free and contaminant free."
            image={homeProduct}
            btnLabel="Discover Retail Products"
            url="/retail-products/dried-porcini"
            secondButton={true}
            btnLabelSecondary="Discover Restaurant Products"
            urlSecondary="/restaurant-products/restaurant-dried-porcini"
          />
        </div>
        <div className="carousel-item">
          <WHPRoductHome
            labelHome="Mom's Plum Jam, Special care is taken to follow generations of recipes to perfect the flavor. It grows, ripens, and is harvested in peak timing and is made with care and love. It synchronizes us with the seasons and connects us to the untamed parts of our nature."
            image={homeProductJam}
            btnLabel="Discover"
            url="/retail-products/moms-jam"
          />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicatorsHomeProduct"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicatorsHomeProduct"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </StyledCarousel>
  );
};

export default WHCarouselHomeProduct;
