import axios from 'axios';
import * as yup from 'yup';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import WHButton from '../WHButton/WHButton';
import WHLabel from '../WHLabel/WHLabel';
import WHModal from '../WHModal/WHModal';
import {
  StyledFormWrapper,
  StyledInput,
  StyledButtonContainer,
  StyledTextarea,
} from './WHContactForm.styled';

const WHContactForm = () => {
  const initialValues = {
    name: '',
    email: '',
    textarea: '',
  };

  const url = 'https://wilde-hills-node.onrender.com/api/contact-form';

  const [form, setForm] = useState(initialValues);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  // const [showModalSuccess, setShowModalSuccess] = useState(false);
  // const [showModalFail, setShowModalFail] = useState(false);

  const formHandler = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    textarea: yup.string().required(),
  });

  const resetForm = () => setForm(initialValues);

  const onSubmit = async () => {
    const isValid = await schema.isValid(form);

    if (isValid) {
      setError(false);
      setShowLoader(true);

      try {
        await axios.post(url, {
          name: form.name,
          email: form.email,
          textarea: form.textarea,
        });

        resetForm();
        setShowLoader(false);
        alert('Your message has been successfully sent!');
        // setShowModalSuccess(true);
      } catch (error) {
        setShowLoader(false);
        alert("We're sorry, but something went wrong, please try again.");
        // setShowModalFail(true);
      }
    } else {
      setError(true);
    }
  };

  return (
    <StyledFormWrapper className="text-center" id="contact">
      <WHLabel tag="h2" text="Contact us!" className="text-center" />
      <WHLabel
        tag="p"
        className="text-center"
        text={
          error
            ? 'All fields are required'
            : 'Please contact us if you have any questions.'
        }
      />
      <Row>
        <Col
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          <StyledInput
            value={form.name}
            name="name"
            type="text"
            placeholder="Name"
            onChange={formHandler}
          />

          <StyledInput
            value={form.email}
            name="email"
            type="email"
            placeholder="Email"
            onChange={formHandler}
          />

          <StyledTextarea
            name="textarea"
            value={form.textarea}
            placeholder="Your message"
            rows="4"
            onChange={formHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          <StyledButtonContainer className="mt-5">
            <WHButton
              data-toggle="modal"
              data-target="#staticBackdrop"
              label="Send message"
              btnStyle="secondary"
              onClick={onSubmit}
              showLoader={showLoader}
            />
          </StyledButtonContainer>
          {/* {showModalSuccess && <WHModal modalSuccess />} */}
          {/* {showModalFail && <WHModal modalFail />} */}
        </Col>
      </Row>
    </StyledFormWrapper>
  );
};

export default WHContactForm;
